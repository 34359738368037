<template>
    <div  id="global-work-space" :key="rerenderApp" class="">
        <div style="font-size:1em;" />

        <div v-if="okDisplay()" id="ok-display">
            <router-view   />
        </div>

        <div v-else-if="!okDisplay()" id="small-display" class="small-display">
            {{ $t('small-display') }}.
        </div>

        <DebuggerWin   />

        <div    :key="rerenderTechInfo">
            <!--{{ startDebugger }}-->
            <TechInfoForAdmin   v-if="parseInt(startDebugger)===1" 
                                :adminIP="adminIP" 
                                :currentIP="currentIP" 
                                :serialNo="serialNo"
                                :restAPI="axiosPath"  
                                :techInfo="techInfo"  
                                :key="rerenderTechInfo"     />
        </div>

    </div>
</template>

<script>
    import  setApp              from    "@/App.json"
    import  axios               from    "axios"

    import  DT                  from    '@/assets/modules/DateTime'
    import  ASYNC               from    '@/assets/modules/AsyncData'
    import  URL                 from    '@/assets/modules/URLMethodsClass'
    import  OBJ                 from    '@/assets/modules/OBJMethodsClass'
    import  SPEC                from    '@/assets/modules/Specials'
    import  PROC                from    '@/assets/modules/dbProc'

    import  TechInfoForAdmin    from    './components/TechInfoForAdmin.vue'
    import  DebuggerWin         from    '@/components/DebuggerWin.vue'

    export default {
        name:'app',
        created () {
            window.addEventListener(  'click',  this.restartComeBack)
           //console.log('START')
            //this.controlInterval =  setInterval(() => this.ASYNC.chargerDataRead(), 10000)
            //this.globalInterval  =  setInterval(() => this.comeBackEvents_app(2), 10000)
        },
        components: {
            TechInfoForAdmin,
            DebuggerWin,
        },
        data() {
            return {
                ASYNC:                      new ASYNC(this),
                DT:                         new DT(this),
                URL:                        new URL(this),
                OBJ:                        new OBJ(this),
                SPEC:                       new SPEC(this),
                PROC:                       new PROC(this),

                smallDisplayMargin:         (screen.height/3) + 'px',

                oTechInfo:                  [],
                oCurrentCharger:            [],
                oFlagsFromThisCharger:      [],
                oSliders:                   [],

                //For Async Read Data
                isAsyncDataRead:            '',
                isActiveApp:                0,
                //=================================

                //Tech info

                isAdminIP:                  this.$store.state.oTechInfo.isAdminIP,
                adminIP:                    this.$store.state.oTechInfo.adminIP,
                currentIP:                  this.$store.state.oTechInfo.currentIP,
                currentHostAddr:            this.$store.state.oTechInfo.currentHostAddr,
                serialNo:                   this.$store.state.oTechInfo.serialNo,
                axiosPath:                  this.$store.state.oTechInfo.axiosPath,
                startDebugger:              this.$store.state.oTechInfo.debugger,              

                oChargers:                  [],
                oCurrentIP:                 [],

                restAPI:                    '',
                techInfo:                   0,

                //=================================
                isDatachange:               0,

                rerenderTechInfo:           new Date(),    
                rerenderApp:                new Date(),    
            }
        },
        watch:  {
            oCurrentIP()    {
                //console.log(this.oCurrentIP)

                let isAdminIP = false
                for(let IP of this.oCurrentIP.oAdminsIP)   {
                    if(this.oCurrentIP['current-ip'].trim()===IP['admin-IP'].trim())   {
                        isAdminIP = true
                        break
                    }
                }

                let isTesterIP = false
                for(let IP of this.oCurrentIP.oTestersIP)   {
                    if(this.oCurrentIP['current-ip'].trim()===IP['tester-IP'].trim())   {
                        isTesterIP = true
                        break
                    }
                }                

                if(isAdminIP || isTesterIP) {/**/}

                let readNetHeavenlyGate = false
                if(typeof localStorage.netHeavenlyGate !== 'string')    {
                    localStorage.netHeavenlyGate = ''
                    readNetHeavenlyGate = true
                }

                if(localStorage.netHeavenlyGate.trim().length === 0)    
                    readNetHeavenlyGate = true  

                //console.log('netHeavenlyGate :: ' + localStorage.netHeavenlyGate)
                if(readNetHeavenlyGate) {
                    //console.log('setTypeApp :: ' +  localStorage.setTypeApp)

                    switch(parseInt(localStorage.setTypeApp)) {
                        case 1: {

                            localStorage.netHeavenlyGate = setApp.developerGate
                            break
                        }
                        case 2: {

                            if(isAdminIP)  {

                                localStorage.netHeavenlyGate =   setApp.developerGate
                            } else {

                                localStorage.netHeavenlyGate =   setApp.testerGate
                            }
                            break    
                        }
                        case 3: {
                            console.log(setApp.demoGate)        
                            localStorage.netHeavenlyGate =   setApp.demoGate
                            break    
                        }
                    }

                    //console.log(localStorage.netHeavenlyGate)

                    /*
                    if(this.oCurrentIP['admin-ip']===this.oCurrentIP['current-ip']) 

                        localStorage.netHeavenlyGate =   setApp.developerGate
                    else

                        localStorage.netHeavenlyGate =   setApp.testerGate
                        */

                }

                this.comeBackEvents_app(1)

                this.ASYNC.chargerDataRead()                

                this.rerenderTechInfo  =   new Date()
                //zzzzzzzzzzz
            },
            oChargers() {
                //console.log('APP WATCHER')
                //console.log(this.oChargers)
                this.$store.commit('oAllChargersToVuex', this.oChargers)
            },

            isAsyncDataRead()   {

                this.rerenderTechInfo  =   new Date()

                let oFlags = this.$store.state.oFlagsFromThisCharger

                if(this.OBJ.count(oFlags)>0) {
                    this.$i18n.locale = oFlags[0]['lng_code']
                    localStorage.currentLocale = oFlags[0]['lng_code']
                }

                localStorage.isActiveApp =this.isActiveApp

                //console.log(localStorage.devGlobalInt)
                //console.log(localStorage.devControlInt)


                //console.log(this.$store.state.oCurrentCharger)

                //console.log(this.$store.state.oRegionManageCMS)
                //console.log(this.$store.state.oRegionLinesCMS)/
                //console.log(this.$store.state.oRegionInterTextsCMS)

                //console.log(this.$store.state.oSliders)

                //console.log(this.$store.state.oTechInfo)

                this.isAdminIP          = this.$store.state.oTechInfo.isAdminIP
                this.adminIP            = this.$store.state.oTechInfo.adminIP
                this.currentIP          = this.$store.state.oTechInfo.currentIP
                this.currentHostAddr    = this.$store.state.oTechInfo.currentHostAddr
                this.serialNo           = this.$store.state.oTechInfo.serialNo
                this.axiosPath          = this.$store.state.oTechInfo.axiosPath
                this.startDebugger      = this.$store.state.oTechInfo.debugger

                localStorage.isAdminIP          = this.isAdminIP
                localStorage.adminIP            = this.adminIP
                localStorage.currentIP          = this.currentIP
                localStorage.currentHostAddr    = this.currentHostAddr
                localStorage.serialNo           = this.serialNo
                localStorage.axiosPath          = this.axiosPath
                localStorage.startDebugger      = this.startDebugger

                this.rerenderApp   = new Date()
                this.isDatachange = 1
            },
            isDatachange()  {

                this.isDatachange = 0
                clearInterval(this.controlInterval)
                clearInterval(this.globalInterval)                
                this.controlInterval = null
                this.globalInterval = null


                //console.log('CONTROL INTERVAL :: ' + localStorage.devControlInt)
                this.controlInterval =  setInterval(() => this.ASYNC.chargerDataRead(), localStorage.devControlInt)
                this.globalInterval  =  setInterval(() => this.comeBackEvents_app(2), localStorage.devGlobalInt)

            },
            isActiveApp()   {
                localStorage.isActiveApp = this.isActiveApp
                this.rerenderTechInfo = new Date()
            },
        },
        methods: {
            okDisplay() {return screen.width > 799 && screen.height > 599},
            comeBackEvents_app()   {    //param
                //console.log('comeBackEvents_app')

                this.$router.push('/')
            },
            restartComeBack() {

                if(parseInt(localStorage.isActiveApp)===0)  return
                //console.log('restartComeBack :: ' + new Date() )
                this.isDatachange = 1

                //console.log(this.controlInterval)
                ///console.log(this.globalInterval) 

                clearInterval(this.controlInterval)
                clearInterval(this.globalInterval) 
                this.controlInterval = null
                this.globalInterval = null

                //console.log(this.controlInterval)
                //console.log(this.globalInterval) 


                //this.controlInterval =  setInterval(() => this.ASYNC.chargerDataRead(), localStorage.devControlInt)
                //this.globalInterval  =  setInterval(() => this.comeBackEvents_app(2), localStorage.devGlobalInt)                

            },
        },
        mounted () {
            console.log('MOUNTED !!!!')
            //console.log(typeof localStorage.startDebugger)
            if(typeof   localStorage.startDebugger !=='undefined') {
                //console.log(localStorage.startDebugger)
                this.isAdminIP      =   localStorage.isAdminIP
                this.adminIP        =   localStorage.adminIP
                this.currentIP      =   localStorage.currentIP
                this.serialNo       =   localStorage.serialNo
                this.axiosPath      =   localStorage.axiosPath
                this.startDebugger  =   localStorage.startDebugger
                this.rerenderTechInfo = new Date()

                if(parseInt(this.OBJ.count(this.$store.state.oCurrentCharger))===0) 
                    this.ASYNC.chargerDataRead()         
            }
          
            localStorage.madeIn = '30.05.23 05:00'
            //localStorage.developerMail  = 'api@abnet.sk'
            localStorage.developerMail  = setApp.developerMail

            //localStorage.gate = 'net-heavenly-gate'
            localStorage.gate = setApp.gate

            //---------------------------------------------------
            localStorage.timeStamp      =   new Date()
            let realLocalhost = window.location.href.toLowerCase().indexOf('http://localhost:')>(-1)
            let URL = ''
            if(!realLocalhost)  {
                if(window.location.href.trim().toLowerCase().indexOf('https:')!==0) {
                    let aURL = window.location.href.split('/')
                    let comma = ''
                    for(let i=0;i<aURL.length;++i)  {
                        if(i===0) {URL += 'https:'}   else  {URL += comma + aURL[i]}       
                        comma = '/'        
                    }
                    window.location.href = URL + '?&timestamp=' + localStorage.timeStamp
                }
            }
            let netHeavenlyGate = this.URL.getParameterFromURL('net-heavenly-gate')
            //---------------------------------------------------

            
            
            //https://dev-app.econec.tech
            let development = false
            if(window.location.href.indexOf('https://dev-app.')>(-1))
                development = true

            localStorage.development = development
            localStorage.realLocalhost = realLocalhost

            let setTypeApp = 0
            let projectName = ''
            if(realLocalhost)   {

                projectName  = setApp.localhost    //   'local'
                setTypeApp = 1
            }   else    {
                
                if(window.location.href.indexOf('dev-app')>(-1))    {

                    projectName  = setApp.tester   //'tester'
                    setTypeApp = 2
                }

                if(window.location.href.indexOf('demo-app')>(-1))    {

                    projectName =   setApp.projectName   //'econec'
                    setTypeApp = 3
                }                

                if(parseInt(setTypeApp)===0)       {

                    projectName =   setApp.projectName      //'econec'
                    setTypeApp = 4
                }
            }

            console.log('Project ('+setTypeApp+') :: '+projectName)
            localStorage.setTypeApp =   setTypeApp

            this.PROC.readChargersForDebugger(  'App.vue',
                                                'oChargers')

            //this.PROC.readAllChargers(  'App.vue',
                                        //'oChargers')

            localStorage.projectName        =   projectName
            localStorage.netHeavenlyGate    =   netHeavenlyGate


            //if(localStorage.netHeavenlyGate.trim().length===0)
                //localStorage.netHeavenlyGate  = setApp.developerGate   

            switch(setTypeApp) {   
                case 1:  {localStorage.restAPI =    setApp.localApi;    break}
                case 2:  {localStorage.restAPI =    setApp.testApi;     break}
                case 3:  {localStorage.restAPI =    setApp.testApi;      break}
                case 4:  {localStorage.restAPI =    setApp.restApi;      break}
            }
                /*
            switch(projectName) {
                case setApp.localhost:  {
                    localStorage.restAPI    =   setApp.localApi
                    break
                }
                case setApp.tester:  {
                    localStorage.restAPI    =   setApp.testApi
                    break
                }
                case setApp.projectName:  {
                    localStorage.restAPI    =   setApp.restAPI
                    break
                }                
            }
            */

            
            /*
            if(realLocalhost)  
                //localStorage.restAPI = 'https://test-api.econec.tech'
                localStorage.restAPI    =   setApp.testApi
            else 
                //localStorage.restAPI = 'https://rest-api.econec.tech'   
                localStorage.restAPI    =   setApp.restApi
            */

            localStorage.weatherIcons   =   'https://data.econec.tech/weather/'
            localStorage.dataPath       =   'https://data.econec.tech'

            localStorage.pathIcons      =   'https://data.econec.tech/icons/'
            localStorage.weatherIcons   =   'https://data.econec.tech/icons-weather/'

            localStorage.flagsSVGPath   =   '/flags-svg/'
            localStorage.logosPath      =   '/logos/'
            localStorage.videosPath     =   '/videos/'
            localStorage.slidersPath    =   '/sliders/' 

            localStorage.cmsImages      =   'http://cms-images.econec.tech/'

            localStorage.videos         =   'https://videos.econec.tech/'

            localStorage.AppType            =   ''

            localStorage.timeAsyncReadData  =   ''
            localStorage.timeSendError      =   ''


            //console.log('START APP :: ' + localStorage.netHeavenlyGate)
            if(localStorage.netHeavenlyGate.trim().length===0)  {

                //console.log('INN :: ' + localStorage.netHeavenlyGate)
                
                //console.log('REST API :: ' + localStorage.restAPI)

                let formData = this.SPEC.formData('App.js')
                //formData.append('command', 'ip')
                formData.append('command', 'proc-getCurrentIP')
                
                //console.log('BEFORE AXIOS IP')
                axios.post(localStorage.restAPI, formData).then(response => {
                    //console.log('AFTER AXIOS IP')
                    //console.log(response.data)
                    if(!this.SPEC.isErrorResponseData('App.js', formData, response, true, true))    {

                        //console.log('IP')
                        this.oCurrentIP = response.data

                        /*
                        if(response.data['admin-ip']===response.data['current-ip']) {
                            //netHeavenlyGate = '99997xQQc7DV6RukzI570W0OoF'
                            netHeavenlyGate =   setApp.developerGate
                        } else {
                            netHeavenlyGate = setApp.testerGate
                        }
                        */
                   
                        //localStorage.netHeavenlyGate = netHeavenlyGate
                        //console.log(localStorage.restAPI)
                        
                        //console.log('SERIAL TEST :: ' + localStorage.netHeavenlyGate)

                        /*
                        TO DO
                        this.comeBackEvents_app(1)
                        */
                    
                        //console.log('ASYNC AWAIT')

                        
                        /*
                        TO DO
                        this.ASYNC.chargerDataRead()
                        */                        
                    }

                }).catch(function(error) {new SPEC(this).axiosErrorCatch('App.vue',error, formData)})
    
            }   else {

                //console.log('SERIAL:: ' + localStorage.netHeavenlyGate)
                this.comeBackEvents_app(1)
                //console.log('ASYNC AWAIT')
                this.ASYNC.chargerDataRead()         
                
                this.rerenderTechInfo  =   new Date()
            }
        },
    }

</script>

<style lang="scss" scoped>
@import "@/assets/global-styles.scss";

$smallDisplayMargin:v-bind(smallDisplayMargin);
.small-display  {
    margin:$smallDisplayMargin auto 0 auto;
    color:#cc0000;
    font-family: tahoma;
    font-size:2em;
    font-weight: 600;
    text-align: center;
    width:90%;
    line-height: 50px;
}
</style>