
import axios    from    "axios"
import DT       from    '@/assets/modules/DateTime'
import OBJ      from    '@/assets/modules/OBJMethodsClass';

export default class Specials {

constructor(App) {

    this.App            =   App
    this.DT             =   new DT(this.App)
    this.OBJ            =   new OBJ(this.App)

    this.notDetected    =   'Not detected'
}


formData = (axiosPath='')   =>  {

    let formData = new FormData()

    if(axiosPath.trim().length>0)
        formData.append('axios', axiosPath)
    else
        formData.append('axios', '')

    formData.append('from-app',             'is')
    formData.append('net',                  localStorage.projectName)  

    formData.append('net-heavenly-gate',    localStorage.netHeavenlyGate )
      
    //formData.append('api',                  localStorage.URLRestApi)   
    formData.append('api',                  localStorage.restAPI)   
    //localStorage.restAPI

    return formData
} 



sendProcessAboutError   =   (   isType,
                                title,
                                axios_,
                                oFormData, 
                                oResponseData)  =>  {

    let oTechInfo, netHeavenlyGate = '' //, sFormData
    if(localStorage.timeSendError!==this.DT.stdFromJSDateTime(new Date(), true, false)) {

        localStorage.timeSendError = this.DT.stdFromJSDateTime(new Date(), true, false)

        if(typeof localStorage.netHeavenlyGate === 'string')    
            netHeavenlyGate = localStorage.netHeavenlyGate          

        if(netHeavenlyGate.trim().length===0)
            if(typeof oFormData.get('net-heavenly-gate') === 'string')
                netHeavenlyGate = oFormData.get('net-heavenly-gate')

        if(this.OBJ.count(this.App.$store.state.oTechInfo)>0)
            oTechInfo = this.App.$store.state.oTechInfo
        else
            oTechInfo = this.getTechInfo()

        if(netHeavenlyGate.trim().length===0)   {

            netHeavenlyGate = oTechInfo.serialNo
        
            if(typeof netHeavenlyGate !== 'string')     netHeavenlyGate = oTechInfo.serialNo
            if(netHeavenlyGate.trim() === 0)            netHeavenlyGate = oTechInfo.serialNo
        }

        let fields  =   '{'
        fields      +=  '"netHeavenlyGate":"'+netHeavenlyGate+'",' 
        fields      +=  '"type":"'+isType+'",' 

        if(typeof oResponseData.error === 'string')
            fields      +=  '"error":"'+oResponseData.error+'",'
        else
            fields      +=  '"error":"'+title+'",'

        fields      +=  '"axios":"'+axios_+'",'
        fields      +=  '"fromApp":"is",'
        fields      +=  '"projectName":"'+localStorage.projectName+'",'
        fields      +=  '"restApi":"'+localStorage.restAPI+'",'

        if(typeof oFormData.get('command') === 'string')
            fields      +=  '"processName":"'+oFormData.get('command')+'", '
        else
            fields      +=  '"processName":"not detected", '

        //sFormData = this.createFormDataForSend(oFormData)
        //if(typeof sFormData === 'string')   
            //fields      +=  '"formData":"'+sFormData+'",'
        ///else
        fields      +=  '"formData":"not detected",'

        if(typeof oResponseData.ip === 'string') {

            fields      +=  '"ip":"'+oTechInfo.currentIP+'",'
        } else {
            
            if(typeof oTechInfo.currentIP === 'string')   
                fields      +=  '"ip":"'+oTechInfo.currentIP+'",'
            else
                fields      +=  '"ip":"not detected",'       
        }

        if(typeof oResponseData.hostAddr === 'string') {

            fields      +=  '"hostAddr":"'+oResponseData.hostAddr+'",'
        } else {
    
            if(typeof oTechInfo.currentHostAddr === 'string')   
                fields      +=  '"hostAddr":"'+oTechInfo.currentHostAddr+'",'
            else
                fields      +=  '"hostAddr":"not detected",'
        }

        if(typeof oResponseData.id === 'string')

            fields      +=  '"errMesssageId":"'+oResponseData.id+'",'       
        else
            fields      +=  '"errMesssageId":"0",'

        if(typeof oResponseData.sector === 'string')
            fields      +=  '"serverProcName":"'+oResponseData.sector+'",'
        else
            fields      +=  '"serverProcName":"",'

        if(typeof oResponseData.line === 'string')
            fields      +=  '"serverProcLine":"'+oResponseData.line+'",'
        else
            fields      +=  '"serverProcLine":"",'

            
        if(typeof oTechInfo.isAdminIP === 'string')   {
            //console.log(oTechInfo.isAdminIP)
            //console.log(oTechInfo.isAdminIP.trim()==='true')
            if(oTechInfo.isAdminIP.trim()==='true')
                fields      +=  '"isAdminIP":"1"'
            else
                fields      +=  '"isAdminIP":"0"'
        
        } else  fields      +=  '"isAdminIP":"0"'
        
        fields      +=  '}'
        //console.log(fields)
        
        let formData = this.formData(axios_)  
        formData.append('command',  'proc-sendProcessAboutError')
        formData.append('fields',   fields)

        //console.log(fields)

        axios.post(localStorage.restAPI, formData).then(response => {
            console.log(response.data)
        })        
    }
}

isErrorResponseData =   (   axios_='', 
                            oFormData, 
                            response, 
                            requiredObject=false, 
                            mustBeGreaterThanZero=false,
                            simulatingError=0)  =>  { 
    
    let returnError = 0, errorText = '', oData = []

    if( (typeof response.data !== 'object' && requiredObject) || 
        parseInt(simulatingError)===1)   {

        errorText = 'Odpoveď z REST API nie je typu = object =, aj keď to systém vyžaduje'
        this.sendProcessAboutError( 1, errorText,
                                    axios_,
                                    oFormData, 
                                    response.data)

        console.log('ERR :: 1')
        if(parseInt(simulatingError)===0) returnError = 1
    }

    if( typeof response.data === 'object' && mustBeGreaterThanZero   ||
        parseInt(simulatingError)===2)   {

        oData = response.data
        if(parseInt(simulatingError)===2)   {
            let sError  =   '{'
            sError      +=  '"error":"K volaniu procedúry nad REST API aktuálne neexistuje oprávnenie ! ", '
            sError      +=  '"axios":"App.vue / dbProc.App.readChargersForDebugger()", '
            sError      +=  '"hostAddr":"178-143-45-184.static.orange.sk", '
            sError      +=  '"id":"37", ' 
            sError      +=  '"ip": "178.143.45.184", ' 
            sError      +=  '"line": "65", ' 
            sError      +=  '"sector":"SQLProcedures"}' 
    
            oData = JSON.parse(sError)            
        }

        if( this.OBJ.count(oData)===0 ||
            parseInt(simulatingError)===2)   {
            //console.error('EMPTY OBJECT :: ' + axios_)

            errorText = 'Rozhranie REST API vrátilo prázdny objekt pri axios požiadavke, pri ktorej je prázdny objekt neprípustný'
            this.sendProcessAboutError( 2, errorText,
                                        axios_,
                                        oFormData, 
                                        oData)

            console.log('ERR :: 2')
            if(parseInt(simulatingError)===0) returnError = 2
        }        
    }

    if( typeof response.data.error === 'string'  ||
        parseInt(simulatingError)===3) {


        console.log(response.data)
        console.log(response.data.error)
        console.log(axios_)
        console.log(oFormData)
        console.log(oData)

        errorText = 'REST API pri odštartovaní požadovaného procesu zistilo chybu pri spracovaní požiadavky, a zapísalo ju do chybovej databázy'
        this.sendProcessAboutError( 3, response.data.error,
                                    axios_,
                                    oFormData, 
                                    oData)

        console.log('ERR :: 3')
        if(parseInt(simulatingError)===0) returnError = 3
    }

    if(parseInt(returnError)>0) return true

    return false
}

axiosErrorCatch =   (callFromMethod, errorMsg, oFormData, axios_)  =>  {

    this.sendProcessAboutError( 0, errorMsg,
                                axios_,
                                oFormData, 
                                [])

}

createFormDataForSend   =   (oFormData)  =>  {

    let formString, formForSend = '', comma = ''        
    
    for (let formRecord of oFormData.entries()) {

        if(formRecord==='fields')   {
            formString      =   formRecord[1];

            formString      =   formString.replaceAll('{', '')
            formString      =   formString.replaceAll('}', '')
            formString      =   formString.replaceAll('"', '')
            formString      =   formString.replaceAll(' ', '')
            formString      =   formString.replaceAll(':', '♥')    //3
            formString      =   formString.replaceAll(',', '♠')    //6                

            formRecord[1]   =   formString
        }
        formForSend += comma + formRecord[0] + '~' + formRecord[1]
        comma = '|'            
    }

    return formForSend
}
getTechInfo =   ()   =>  {  //oCurrentCharger=[]
 

    let sJSON = '', oTechInfo = []
    if(typeof localStorage.serialNo==='string') {
 
        if(localStorage.serialNo.trim().length>15)  {
 
            sJSON    +=  '{' 
            sJSON    +=  '"isAdminIP":"'+localStorage.isAdminIP+'",' 
            sJSON    +=  '"adminIP":"'+localStorage.adminIP+'",' 
            sJSON    +=  '"currentIP":"'+localStorage.currentIP+'",' 
            sJSON    +=  '"currentHostAddr":"'+localStorage.currentHostAddr+'",' 
            sJSON    +=  '"serialNo":"'+localStorage.serialNo+'",' 
            sJSON    +=  '"axiosPath":"'+localStorage.axiosPath+'",' 
            sJSON    +=  '"debugger":"'+localStorage.startDebugger+'"' 
            sJSON    +=  '}' 

            oTechInfo   =   JSON.parse(sJSON)
            this.App.$store.commit('oTechInfoToVuex', oTechInfo)

            return oTechInfo

        }
    }

    return oTechInfo
   
}

}

